import React from "react";
import LoginPage from "../components/Login/LoginPage";

const Login = () => {
  return (
    <>
      <LoginPage />
    </>
  );
};

export default Login;
