import React from "react";
import BgImage from "../../Images/Italy/italyBg.png"; // Background image of your choice

const HeroSection = () => {
  const scrollingTextStyles = {
    position: "absolute",
    width: "100%",
    whiteSpace: "nowrap",
    animation: "scroll-left-right 30s linear infinite",
    // overflow: "hidden",
  };

  // Add the keyframes for scrolling left to right and then right to left
  const styles = `
@keyframes scroll-left-right {
  0% {
    transform: translateX(50%); /* Start from the right */
  }
  50% {
    transform: translateX(-0%); /* Move to the left */
  }
  100% {
    transform: translateX(100%); /* Go back to the right */
  }
}
`;
  return (
    <div className="relative h-[400px] md:h-[750px] w-full overflow-hidden">
      {/* Background Image */}
      <img
        src={BgImage}
        alt="New Zealand Skyline"
        className="absolute inset-0 h-full w-full object-cover"
        aria-hidden="true"
      />

      {/* Content Container */}
      <div className="relative h-full flex flex-col items-center justify-center text-center space-y-4 px-4 pt-10 md:pt-20">
        {/* Main Title and Overlay */}
        <div className="relative z-10 w-full flex flex-col items-center mt-[3rem]">
          {/* Main Title */}
          <h1
            style={scrollingTextStyles}
            className="text-[44px] text-[#0AC4FF] md:text-[200px] -top-40 font-extralight uppercase tracking-wider z-10 relative"
            // style={{
            //   fontFamily: "Poppins",
            //   color: "#0AC4FF",
            //   letterSpacing: "0.1em",
            // }}
          >
            STUDY IN ITALY
          </h1>

          {/* Subtitle with Blurred Background */}
          <div className="relative z-20 backdrop-blur-sm py-16 max-w-[1290px] mx-auto rounded-md w-full mdw-[60%] bg-black bg-opacity-10 md:-mt-[rem]">
            <p
              className="text-lg  text-white"
              style={{
                fontFamily: "Poppins",
                color: "#FFFFFF",
                letterSpacing: "0.02em",
              }}
            >
              Connect With A Professional Counselor For Free
            </p>
            <p
              className="text-lg md:text-xl mt-1 text-white"
              style={{
                fontFamily: "Poppins",
                letterSpacing: "0.02em",
              }}
            >
              Get Free, Confidential Support From Certified Counselors To
              <br className="hidden md:block" />
              Manage Stress And Anxiety.
            </p>
          </div>
        </div>
      </div>

      {/* Gradient Overlay for the bottom fade effect */}
      {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60"></div> */}
    </div>
  );
};

export default HeroSection;
