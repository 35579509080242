import React from "react";
import BlogCard from "./BlogCard";
import Card1 from "../../Images/Card1.jpg";
import Card2 from "../../Images/card2.jpg";
import Card3 from "../../Images/card3.jpg";
import Card4 from "../../Images/card4.jpg";
import Card5 from "../../Images/card5.jpg";
import Card6 from "../../Images/card6.jpg";
import { Link } from "react-router-dom";

const BlogSection = () => {
  // Sample blog data (updated with categories)
  const blogPosts = [
    {
      image: `${Card1}`,
      title: "Integer Maecenas Eget Viverra",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    {
      image: `${Card2}`,
      title: "Aenean Eleifend Ante Maecenas",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    {
      image: `${Card3}`,
      title: "Integer Maecenas Eget Viverra",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    {
      image: `${Card4}`,
      title: "Integer Maecenas Eget Viverra",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    {
      image: `${Card5}`,
      title: "Integer Maecenas Eget Viverra",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    {
      image: `${Card6}`,
      title: "Integer Maecenas Eget Viverra",
      author: "Joanna Wellick",
      date: "June 28, 2018",
      shares: "1.1K",
      description:
        "Aenean eleifend ante maecenas pulvinar montes lorem ac pede dis dolor pretium...",
      categories: ["Aenean Eleifend", "Aliquam"],
    },
    // ... other blog posts with categories
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <Link to="/blogDetails">
              <BlogCard
                key={index}
                image={post.image}
                title={post.title}
                author={post.author}
                date={post.date}
                shares={post.shares}
                description={post.description}
                categories={post.categories}
              />
            </Link>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <button className="text-gray-700 font-bold text-xl py-2 px-10 border border-black">
            Load More
          </button>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
