import React from "react";
import Img from "../../Images/BlogBackgroundImage.jpg";

const Hero = () => {
  return (
    <header
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${Img})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 text-center text-white flex items-center justify-center h-full">
        <div>
          <h1
            className="mt-24 text-4xl font-normal text-center max-sm:text-2xl"
            style={{ letterSpacing: "3px" }}
          >
            INSPIRATION FOR TRAVEL BY REAL PEOPLE
          </h1>
          <p className=" text-xl font-light">Book smart, travel simple</p>
          <button
            className="mt-6 px-6 py-3 bg-white text-black font-normal text-xl border rounded-md shadow-lg max-sm:px-3 max-sm:py-2 max-sm:text-base"
            style={{ borderRadius: "2px" }}
          >
            Start planning your trip
          </button>
        </div>
      </div>
    </header>
  );
};

export default Hero;
