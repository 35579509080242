import React from "react";
import second from "./careerasserts/v875-katie-35 1.png";
import "./style.scss";
import { Link } from "react-router-dom";
const Getintouch = () => {
  return (
    <div className="h-[700px] hey flex justify-center items-center bg-cover bg-center">
      <div className="mb-12 text-center">
        <h3 className="text-[#000] text-sm">CLIENT FEEDBACK</h3>
        <h1 className="text-3xl font-bold mt-2">Get in Touch</h1>
        <p className="text-[#000] mt-2 max-w-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>
        {/* <button className="px-6  mt-10 py-3 font-medium text-white bg-[#233871] rounded-[10px] hover:bg-blue-800">
          Contact Us
        </button> */}

        <div className="flex items-center justify-center">
          <Link
            to="/career"
            class="group relative z-0 bg-[#233871] bg rounded-full w-[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-6 py-3 text-white transform-gpu duration-300 ease-in-out active:translate-y-[1px] mt-[1.6rem]"
          >
            Contact Us
            <span class="group-hover:rotate-45 ml-4 ">↗</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Getintouch;
