import React from "react";
// import img from "./careerasserts/woman-graduate-÷student-wearing-graduation-hat-gown-isolated-white 1.png";
import img from "./careerasserts/Group 1000006546.png";
import { Link } from "react-router-dom";

const CareerHero = () => {
  const scrollTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section className="flex items-center justify-between pl-8 pt-16 bg-[#F9F9F9]">
        {/* Left Side Content */}
        <div className="max-w-lg space-y-6">
          <h1 className="text-5xl font-bold leading-tight text-gray-900 font-playfair">
            Practical <span className="text-yellow-500">Solutions</span> to
            Complex Problems
          </h1>
          <p className="text-gray-600 font-inter">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
          {/* Buttons */}
          <div className="flex space-x-6">
            {/* <Link
              to="/career"
              className="hover:text-blue-700 font-medium flex pr-[1rem] text-[15px] items-center justify-center pb-0 text-grey-900"
            >
              <button className="px-6 py-3 font-medium text-white bg-[#233871] rounded-[10px] hover:bg-blue-800">
                Contact Us
              </button>
            </Link> */}

            <Link
              to="/career"
              onClick={scrollTotop}
              class="group relative z-0 bg-[#233871] bg rounded-full w-[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-6 py-3 text-white transform-gpu duration-300 ease-in-out active:translate-y-[1px] mt[1.6rem]"
            >
              Contact Us
              <span class="group-hover:rotate-45 ml-4 ">↗</span>
            </Link>
            <button className="flex items-center space-x-2 text-black hover:underline">
              <Link
                to="/psychometric-test"
                className="hover:text-blue-700 font-medium flex pr-[1rem] text-[15px] items-center justify-center pb-0 text-grey-900"
              >
                <span>Explore Aspirent's Ally</span>
                <span className="text-lg">→</span>
              </Link>
            </button>
          </div>
          {/* Statistics */}
          <div className="flex space-x-10 mt-8  items-center">
            <div className="text-center bg-[#fff] rounded-[43px] p-10">
              <h2 className="text-3xl font-bold text-blue-900">12K+</h2>
              <p className="font-semibold text-gray-800">Consultancy Done</p>
              <p className="text-gray-600">Lorem ipsum dolor</p>
            </div>
            <div className="text-center">
              <h2 className="text-3xl font-bold text-blue-900">6,250+</h2>
              <p className="font-semibold text-gray-800">Our Student</p>
              <p className="text-gray-600">Lorem ipsum dolor</p>
            </div>
          </div>
        </div>

        {/* Right Side Image */}
        <div className="relative w-[60%] mt-20">
          <img
            src={img} // Replace this with the actual path to your image
            alt="Graduate"
            className="rounded-lg"
          />
        </div>
      </section>
    </>
  );
};

export default CareerHero;
