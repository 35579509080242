import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.scss";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { FaArrowLeft, FaArrowRight, FaStar } from "react-icons/fa";
import ShimmerButton from "../magicui/shimmer-button";

const CourseSection = () => {
  const [activeTab, setActiveTab] = useState("all");
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.navigation.update(); // Update the Swiper navigation
    }
  }, [activeTab]);

  const courses = {
    all: [
      {
        id: 1,
        title: "MBBS Course 1",
        imgSrc: "/one.jpeg",
        description: "Description for NEET Course 1",
      },
      {
        id: 2,
        title: "Engineering Course 1",
        imgSrc: "/two.jpeg",
        description: "Description for Engineering Course 1",
      },
      {
        id: 3,
        title: "Management Course 1",
        imgSrc: "/three.jpeg",
        description: "Description for Management Course 1",
      },
      {
        id: 4,
        title: "MBBS Course 2",
        imgSrc: "/four.jpeg",
        description: "Description for NEET Course 2",
      },
      {
        id: 5,
        title: "Engineering Course 2",
        imgSrc: "/one.jpeg",
        description: "Description for Engineering Course 2",
      },
      {
        id: 6,
        title: "Management Course 2",
        imgSrc: "/two.jpeg",
        description: "Description for Management Course 2",
      },
      {
        id: 7,
        title: "MBBS Course 3",
        imgSrc: "/three.jpeg",
        description: "Description for NEET Course 3",
      },
      {
        id: 8,
        title: "Engineering Course 3",
        imgSrc: "/four.jpeg",
        description: "Description for Engineering Course 3",
      },
      {
        id: 9,
        title: "Management Course 3",
        imgSrc: "/one.jpeg",
        description: "Description for Management Course 3",
      },
      {
        id: 10,
        title: "MBBS Course 4",
        imgSrc: "/two.jpeg",
        description: "Description for NEET Course 4",
      },
      {
        id: 11,
        title: "Engineering Course 4",
        imgSrc: "/three.jpeg",
        description: "Description for Engineering Course 4",
      },
      {
        id: 12,
        title: "Management Course 4",
        imgSrc: "/four.jpeg",
        description: "Description for Management Course 4",
      },
    ],
    neet: [
      {
        id: 1,
        title: "MBBS Course 1",
        imgSrc: "/one.jpeg",
        description: "Description for NEET Course 1",
      },
      {
        id: 2,
        title: "MBBS Course 2",
        imgSrc: "/two.jpeg",
        description: "Description for NEET Course 2",
      },
      {
        id: 3,
        title: "MBBS Course 3",
        imgSrc: "/three.jpeg",
        description: "Description for NEET Course 2",
      },
      {
        id: 4,
        title: "MBBS Course 4",
        imgSrc: "/four.jpeg",
        description: "Description for NEET Course 2",
      },
      {
        id: 5,
        title: "MBBS Course 5",
        imgSrc: "/three.jpeg",
        description: "Description for NEET Course 5",
      },
      {
        id: 6,
        title: "MBBS Course 6",
        imgSrc: "/four.jpeg",
        description: "Description for NEET Course 6",
      },
    ],
    engineering: [
      {
        id: 1,
        title: "Engineering Course 1",
        imgSrc: "/one.jpeg",
        description: "Description for Engineering Course 1",
      },
      {
        id: 2,
        title: "Engineering Course 2",
        imgSrc: "/two.jpeg",
        description: "Description for Engineering Course 2",
      },
      {
        id: 3,
        title: "Engineering Course 2",
        imgSrc: "/three.jpeg",
        description: "Description for Engineering Course 2",
      },
      {
        id: 4,
        title: "Engineering Course 4",
        imgSrc: "/four.jpeg",
        description: "Description for Engineering Course 2",
      },
      {
        id: 5,
        title: "Engineering Course 5",
        imgSrc: "/three.jpeg",
        description: "Description for Engineering Course 5",
      },
      {
        id: 6,
        title: "Engineering Course 6",
        imgSrc: "/four.jpeg",
        description: "Description for Engineering Course 6",
      },
    ],
    management: [
      {
        id: 1,
        title: "Management Course 1",
        imgSrc: "/three.jpeg",
        description: "Description for Management Course 1",
      },
      {
        id: 2,
        title: "Management Course 2",
        imgSrc: "/one.jpeg",
        description: "Description for Management Course 2",
      },
      {
        id: 3,
        title: "Management Course 2",
        imgSrc: "/two.jpeg",
        description: "Description for Management Course 2",
      },
      {
        id: 4,
        title: "Management Course 2",
        imgSrc: "/four.jpeg",
        description: "Description for Management Course 4",
      },
      {
        id: 5,
        title: "Management Course 5",
        imgSrc: "/two.jpeg",
        description: "Description for Management Course 5",
      },
      {
        id: 6,
        title: "Management Course 6",
        imgSrc: "/four.jpeg",
        description: "Description for Management Course 6",
      },
    ],
  };

  return (
    <div className="mx-auto h-screen md:p-20 relative bg-gray-50 w-full py-12">
      <div className=" h-20 top-32 text-center z-40 shadow-2xl bg-gray-50 shadow-gray-50">
        <h2 className="text-3xl font-bold text-[#3e5ba9]">
          Explore Our World's Best{" "}
          <span className="text-[#f6c16f]">Services</span>
        </h2>
        <p className="text-sm text-gray-500">
          Read testimonials from our esteemed students and alumni about studying
          with us.
        </p>
        <div className="mt-2 w-14 h-1 bg-[#f6c16f] mx-auto"></div>
      </div>
      {/* Custom Tabs */}
      <div className="flex flex-wrap justify-center mb-8 space-x-0 sm:space-x2 mt-10 px-8">
        <button
          className={`w-full sm:w-auto px-6 sm:px-6 py-2 mb-4 sm:mb-0 border-b-4 ${
            activeTab === "all"
              ? "border-blue-800 active text-blue-800"
              : "border-gray-300 text-gray-700 hover:border-gray-400 hover:text-blue-800"
          }`}
          onClick={() => setActiveTab("all")}
        >
          All
        </button>

        <button
          className={`w-full sm:w-auto px-6 sm:px-6 py-2 mb-4 sm:mb-0 border-b-4 ${
            activeTab === "neet"
              ? "border-blue-800 active text-blue-800"
              : "border-gray-300 text-gray-700 hover:border-gray-400 hover:text-blue-800"
          }`}
          onClick={() => setActiveTab("neet")}
        >
          MBBS Courses
        </button>

        <button
          className={`w-full sm:w-auto px-6 sm:px-6 py-2 mb-4 sm:mb-0 border-b-4 ${
            activeTab === "engineering"
              ? "border-blue-800 active text-blue-800"
              : "border-gray-300 text-gray-700 hover:border-gray-400 hover:text-blue-800"
          }`}
          onClick={() => setActiveTab("engineering")}
        >
          Engineering Courses
        </button>

        <button
          className={`w-full sm:w-auto px-6 sm:px-6 py-2 border-b-4 ${
            activeTab === "management"
              ? "border-blue-800 active text-blue-800"
              : "border-gray-300 text-gray-700 hover:border-gray-400 hover:text-blue-800"
          }`}
          onClick={() => setActiveTab("management")}
        >
          Management Courses
        </button>
      </div>

      {/* Swiper Animation for Course List */}
      <Swiper
        spaceBetween={10}
        //   spaceBetween={30}
        //   centeredSlides={true}
        slidesPerView={3}
        autoplay={{
          delay: 2500,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        breakpoints={{
          310: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        //   navigation={true}
        loop={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="w-[90%] mx-auto "
      >
        {courses[activeTab].map((course) => (
          <SwiperSlide key={course.id}>
            <div className="shadow-lg rounded-2xl bg-white overflow-hidden p-4 m-1 ">
              <img
                src={course.imgSrc}
                alt={course.title}
                className="w-full h-44 object-cover rounded-[12px]"
              />
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">{course.title}</h3>
                <p className="text-gray-700 text-sm mb-4">
                  {course.description}
                </p>
                <div className="flex items-center justify-between">
                  <button className="bg-[#F6C16E] outline shad hover:bg-[#e2ad5d] text-black text-sm px-6 py-2 rounded-full ">
                    View Details →
                  </button>
                  <span className="flex items-center gap-1 text-sm font-extralight">
                    ⭐️ . (4.8 Ratings)
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        ref={prevRef}
        className="absolute  md:top-1/2 md:op-1/2 left-16 transform -translate-y-1/2 z-10 p-2 bgwhite rounded-full shadowmd cursor-pointer"
      >
        {/* <FaArrowLeft size={24} className="text-gray-700" /> */}
        <svg
          width="64"
          height="63"
          viewBox="0 0 64 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_20_12058)">
            <rect
              x="4"
              y="0.588867"
              width="60"
              height="60"
              rx="30"
              fill="#233871"
              shape-rendering="crispEdges"
            />
            <rect
              x="4.5"
              y="1.08887"
              width="59"
              height="59"
              rx="29.5"
              stroke="black"
              shape-rendering="crispEdges"
            />
            <path
              d="M24.08 30.9889C24.1333 31.0422 24.16 31.0956 24.16 31.1489L24.32 31.3089L30.32 37.3089C30.48 37.5222 30.6933 37.6289 30.96 37.6289C31.2267 37.6289 31.4667 37.5222 31.68 37.3089C31.8933 37.0956 32 36.8556 32 36.5889C32 36.3222 31.8933 36.0822 31.68 35.8689L27.36 31.6289H42.96C43.28 31.6289 43.5333 31.5356 43.72 31.3489C43.9067 31.1622 44 30.9089 44 30.5889C44 30.2689 43.9067 30.0289 43.72 29.8689C43.5333 29.7089 43.28 29.6289 42.96 29.6289H27.36L31.68 25.3089C31.8933 25.0956 32 24.8556 32 24.5889C32 24.3222 31.9067 24.0956 31.72 23.9089C31.5333 23.7222 31.2933 23.6289 31 23.6289C30.7067 23.6289 30.48 23.7089 30.32 23.8689L24.32 29.8689C24.16 30.0289 24.08 30.1356 24.08 30.1889C24.0267 30.4556 24.0267 30.7222 24.08 30.9889Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_20_12058"
              x="0.4"
              y="0.588867"
              width="63.6"
              height="62.4"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-3.6" dy="2.4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0 0.168627 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_20_12058"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_20_12058"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div
        ref={nextRef}
        className="absolute md:top-1/2 right-16 transform -translate-y-1/2 z-10 p-2 bgwhite rounded-full shadowmd cursor-pointer"
      >
        {/* <FaArrowRight size={24} className="text-gray-700" /> */}
        <svg
          width="64"
          height="63"
          viewBox="0 0 64 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_20_12059)">
            <rect
              y="0.588867"
              width="60"
              height="60"
              rx="30"
              fill="#233871"
              shape-rendering="crispEdges"
            />
            <rect
              x="0.5"
              y="1.08887"
              width="59"
              height="59"
              rx="29.5"
              stroke="black"
              shape-rendering="crispEdges"
            />
            <path
              d="M39.92 30.1888C39.8667 30.1355 39.84 30.0822 39.84 30.0288L39.68 29.8688L33.68 23.8688C33.52 23.6555 33.3067 23.5488 33.04 23.5488C32.7733 23.5488 32.5333 23.6555 32.32 23.8688C32.1067 24.0822 32 24.3222 32 24.5888C32 24.8555 32.1067 25.0955 32.32 25.3088L36.64 29.5488H21.04C20.72 29.5488 20.4667 29.6422 20.28 29.8288C20.0933 30.0155 20 30.2688 20 30.5888C20 30.9088 20.0933 31.1488 20.28 31.3088C20.4667 31.4688 20.72 31.5488 21.04 31.5488H36.64L32.32 35.8688C32.1067 36.0822 32 36.3222 32 36.5888C32 36.8555 32.0933 37.0822 32.28 37.2688C32.4667 37.4555 32.7067 37.5488 33 37.5488C33.2933 37.5488 33.52 37.4688 33.68 37.3088L39.68 31.3088C39.84 31.1488 39.92 31.0422 39.92 30.9888C39.9733 30.7222 39.9733 30.4555 39.92 30.1888Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_20_12059"
              x="0"
              y="0.588867"
              width="63.6"
              height="62.4"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="3.6" dy="2.4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0 0.168627 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_20_12059"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_20_12059"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default CourseSection;
