// MainLayout.js
import Navbar1 from "../components/Navbar/Navbar1";
import FooterNew from "../components/footer/Footer";
import { TopMarquee } from "../components/Navbar/TopMarquee";
import Main from "../components/Navbar/Main";

function MainLayout({ children }) {
  return (
    <>
      <TopMarquee />
      <Main />
      {children}
      <FooterNew />
    </>
  );
}

export default MainLayout;
