import React, { useState } from 'react';

const CategoryList = () => {
  const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6', 'Category 7'];
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setActiveCategory(category); 
  };

  return (
    <div className="w-full bg-white border-b overflow-x-auto">
      <div className="flex space-x-4 font-bold">
        {categories.map((category, index) => (
          <div
            key={index}
            onClick={() => handleCategoryClick(category)}
            className={`whitespace-nowrap px-3 cursor-pointer ${
              activeCategory === category ? 'border-b-2 border-black font-bold' : 'border-b-4 border-transparent'
            }`}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;
