import React from 'react';
import GlobalCitizens from '../components/aboutUsComponent/OurMission';
import OurStory from '../components/aboutUsComponent/ourStory';

const AboutUs = () => {
    return (
    <>
    <div className='pt-24'>
    <GlobalCitizens/>
    </div>
    <OurStory />
    </>
 );
};

export default AboutUs;
