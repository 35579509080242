// import React from "react";
// import mainImage from "../../Images/topDes3.jpg"; // Image for the main post
// import { div } from "framer-motion/client";
// import SidebarPosts from "./SidebarPosts";

// const MainPost = () => {
//   return (
//     <div className=" shadow-lg">
//       <div className=" flex">
//         <div className="w-full lg:w-2/5 bg-white rounded-lg overflow-hidden p-4">
//           <img
//             src={mainImage}
//             alt="Large Post"
//             className="w-full h-64 object-cover"
//           />
//           <div className="p-6">
//             <h2 className="text-xl font-semibold mb-2">
//               Tick one more destination off of your bucket list with one of our
//               most popular vacations in 2022
//             </h2>
//             <p className="text-gray-500 mb-4">1 Month Ago</p>
//             <p className="text-gray-700 mb-4">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
//               tincidunt metus vel turpis vehicula, eget dapibus ipsum auctor.
//               Suspendisse potenti.
//             </p>
//             <button className=" font-semibold text-black border-b-2 border-black rounded-md ">
//               View Post
//             </button>
//           </div>
//         </div>
//         <div >
//         <SidebarPosts />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainPost;

import React from "react";
import mainImage from "../../Images/topDes3.jpg"; // Image for the main post

const MyPage = () => {
  return (
    <div className="container mx-auto px-5 pt-6 pb-3 border w-4/5">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/3">
          <img
            src={mainImage}
            alt="Main Image"
            className="w-full h-64 object-cover rounded-lg mb-4"
          />
          <p
            className="text-gray-500 text-xs mb-4 font-normal"
            style={{ fontFamily: "Poppins" }}
          >
            1 Month Ago
          </p>
          <h2
            className="text-lg font-bold mb-2"
            style={{    }}
          >
            Tick one more destination off of your bucket list with one of our
            most popular vacations in 2022
          </h2>
          <p
            className="text-gray-700 font-normal text-sm"
            style={{ fontFamily: "Poppins" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            tincidunt metus vel turpis vehicula, eget dapibus ipsum auctor.
            Suspendisse potenti.
          </p>
          <button
            className=" text-black font-bold text-base border-b-2 border-black mt-4"
            style={{    }}
          >
            View Post
          </button>
        </div>
        <div className="w-full max-sm:mt-6 sm:mt-8 lg:w-1/3 ml-4 lg:ml-8">
          <div className="space-y-4">
            <div className="flex items-center">
              <img
                src={mainImage}
                alt="Sidebar Image 1"
                className="w-24 h-16 object-cover rounded-lg mr-4"
              />
              <div>
                <h4
                  className="text-md font-bold"
                  style={{    }}
                >
                  Akame Ga Kill: Season finale
                </h4>
                <p
                  className="text-sm text-gray-500 font-normal"
                  style={{ fontFamily: "Europa-Regular" }}
                >
                  21 March 2021
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <img
                src={mainImage}
                alt="Sidebar Image 1"
                className="w-24 h-16 object-cover rounded-lg mr-4"
              />
              <div>
                <h4
                  className="text-md font-bold"
                  style={{    }}
                >
                  Akame Ga Kill: Season finale
                </h4>
                <p
                  className="text-sm text-gray-500 font-normal"
                  style={{ fontFamily: "Europa-Regular" }}
                >
                  21 March 2021
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <img
                src={mainImage}
                alt="Sidebar Image 1"
                className="w-24 h-16 object-cover rounded-lg mr-4"
              />
              <div>
                <h4
                  className="text-md font-bold"
                  style={{    }}
                >
                  Akame Ga Kill: Season finale
                </h4>
                <p
                  className="text-sm text-gray-500 font-normal"
                  style={{ fontFamily: "Europa-Regular" }}
                >
                  21 March 2021
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <img
                src={mainImage}
                alt="Sidebar Image 1"
                className="w-24 h-16 object-cover rounded-lg mr-4"
              />
              <div>
                <h4
                  className="text-md font-bold"
                  style={{    }}
                >
                  Akame Ga Kill: Season finale
                </h4>
                <p
                  className="text-sm text-gray-500 font-normal"
                  style={{ fontFamily: "Europa-Regular" }}
                >
                  21 March 2021
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPage;
