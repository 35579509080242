import React, { useState } from 'react';
import { Link } from 'lucide-react';
import profile from './managementassets/Ethan-Caldwell.webp.png';
import Image from './managementassets/Container.png';
import { MapPin } from 'lucide-react';
import logo1 from "./managementassets/ethics_11966669 1.png";
import logo2 from "./managementassets/digital-transformation_17792211 1.png";
import logo3 from "./managementassets/logistics-management_17718723 1.png";

const ProfileStoriesUI = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
      {
        title: "Dive deep into challenging topics in Physics, Chemistry, and Biology.",
        author: "Ethan Caldwell",
        date: "July 7, 2024",
        tag: "Medical",
        image: Image,
      },
      {
        title: "Explore the wonders of the Universe with hands-on experiments.",
        author: "Sophia Lewis",
        date: "August 15, 2024",
        tag: "Science",
        image: Image,
      },
      {
        title: "Innovations in technology and their impact on healthcare.",
        author: "Ava Garcia",
        date: "September 5, 2024",
        tag: "Technology",
        image: Image,
      },
    ];
  
    const handleDotClick = (index) => {
      setCurrentSlide(index);
    };

  return (
    <div className="max-w-8xl mx-auto p-8 flex gap-8 bg-gray-100">
      {/* Left Content Section - 40% width */}
           <div className="flex-[4] space-y-8">
                <div className="prose max-w-none">
                <p className="text-[#29294B] mb-8">
                    In today's ever-evolving world, storytelling has become a powerful tool for
                    connection. <span className="font-semibold">Revision</span> provides a unique platform for individuals to share
                    their stories.
                </p>
                <p className="text[#29294B] mb-8">
                    Revision is more than a typical content hub. It’s a dynamic space for
                    meaningful conversations and personal stories that resonate with people on
                    an emotional level. Whether you are looking for inspiration, comfort, or just a
                    different perspective on life, Revision offers a wide range of narratives to
                    explore.
                </p>
                <p className="text[#29294B] mb-8">
                    So, what makes Revision stand out as the place for heartfelt reflections?
                </p>
                <p className="text[#29294B] mb-8">
                    Revision is more than a typical content hub. It’s a dynamic space for
                    meaningful conversations and personal stories that resonate with people on
                    an emotional level. Whether you are looking for inspiration, comfort, or just a
                    different perspective on life, Revision offers a wide range of narratives to
                    explore.
                </p>
                <p className="text-[#29294B] mb-8">
                    With <span className="font-semibold">Revision</span> covering everything from love and relationships to personal
                    development and lifestyle, it encourages readers to explore topics that
                    touch on their emotions and experiences.
                </p>
                <h3 className="text[#29294B] text-2xl mb-8 font-semibold mt-8">Stories that Matter</h3>
                <p className="text[#29294B] mb-8">
                    At the core of Revision is a commitment to delivering stories that matter.
                    Unlike traditional media platforms or news, Revision invites readers into a
                    world of deeply personal narratives. The website’s title, "Heartfelt
                    Reflections: Stories of Love, Loss, and Growth," signals this intent clearly,
                    inviting you to journey through the most intimate aspects of human
                    experience.
                </p>
                <p className="text[#29294B] mb-8">
                    But we’re not just talking about written content — there are many ways that
                    Revision fosters connection and creativity. The different types of features
                    include:
                </p>
                <ul className="list-disc pl-8 text[#29294B] mb-8">
                    <li>
                    <span className='font-semibold'>Author Profiles:</span> Each contributor has a detailed profile, allowing readers
                    to connect with their personal journey and social media presence.
                    </li>
                    <li>
                    <span className='font-semibold'>Experience Widgets:</span> Contributors showcase their professional growth
                    and skills, giving readers insight into their expertise.
                    </li>
                    <li>
                    <span className='font-semibold'>Technologies Section:</span> Creators highlight the tools they use, such as
                    Figma, Photoshop, and more, providing transparency in their creative
                    processes.
                    </li>
                    <li>
                    <span className='font-semibold'>Creating Widget:</span> A space where contributors can link to external
                    projects and portfolios, expanding their reach beyond the platform.
                    </li>
                </ul>
                </div>
            </div>


      {/* Right Sidebar Section - 30% width */}
      <div className="flex-[2] space-y-8">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center gap-4 mb-4">
            <img 
              src={profile}
              alt="Author" 
              className="w-10 h-10 rounded-full"
            />
            <div>
              <h3 className="font-semibold">Page Varner</h3>
              <p className="text-sm text-gray-500">Experience Designer</p>
            </div>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            Thanks for checking out my blog site. I am a student at Penn
            State University studying computer science.
          </p>
          <div className="flex items-center text-gray-600 mb-4">
            <MapPin className="w-5 h-5 mr-1 text-gray-500" />
            <p>Bhopal, Madhya Pradesh</p>
            </div>
          <div className="flex gap-2">
            <Link className="w-5 h-5 text-gray-400" />
            <Link className="w-5 h-5 text-gray-400" />
            <Link className="w-5 h-5 text-gray-400" />
          </div>
        </div>

        {/* Experience Timeline */}
        <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
          <h6 className="font-semibold text-gray-900">UPCOMING EVENTS</h6>
          <div className="space-y-4">
            {[
              {
                title: "NIST Preparation Webinar",
                date: "2023",
                description: "Join us as Sarah Harris will lead and demonstrate this framework."
              },
              {
                title: "Meet Text Series",
                date: "2022",
                description: "Working with the team to get more users to see and enjoy using software."
              },
              {
                title: "Open House Details",
                date: "2021",
                description: "Visit our campus, meet faculty and learn about your tech training journey."
              }
            ].map((event, index) => (
              <div key={index} className="border-b-2 border-gray-200 pl-4 py-2">
                <div className="flex justify-between items-center">
                  <h5 className="font-medium text-gray-900">{event.title}</h5>
                  <span className="text-sm text-gray-500">{event.date}</span>
                </div>
                <p className="text-sm text-gray-600 mt-1">{event.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Links Section */}
        <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
            <h6 className="font-semibold text-[#5955D1] text-sm uppercase tracking-wide">Links</h6>
            <div className="space-y-4">
                {[
                { 
                    title: "Apply Now", 
                    link: "#",
                    description: "Submit your application for NEET coaching today and take the first step toward your medical career!"
                },
                { 
                    title: "NEET Exam Details", 
                    link: "#",
                    description: "Find comprehensive information about the NEET exam format, syllabus, and important dates."
                },
                { 
                    title: "Study Resources", 
                    link: "#",
                    description: "Access a wealth of study materials, including notes, practice questions, and video lectures to enhance your preparation."
                }
                ].map((linkItem, index) => (
                <div key={index} className="space-y-1">
                    <a 
                    href={linkItem.link} 
                    className="block text-[#5955D1] hover:text-blue-800 font-medium flex items-center space-x-1"
                    >
                    <span>{linkItem.title}</span>
                    <span className="text-[#5955D1] text-xs">↗</span>
                    </a>
                    <p className="text-sm text-gray-500">
                    {linkItem.description}
                    </p>
                </div>
                ))}
            </div>
            </div>
            

      </div>

      {/* Slider Section - 30% width */}
      <div className="flex-[3] space-y-4">
      <div>
      <h4 className="font-semibold text-gray-900">BLOG</h4>
      <div className="slider-container relative overflow-hidden rounded-lg shadow-lg">
        <div
          className="slider-track flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="slide flex-shrink-0 w-full relative"
            >
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-64 object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 rounded-lg text-white">
              <span className="absolute top-4 left-4 bg-teal-600 text-white px-2 py-1 rounded-full text-xs">
                {slide.tag}
              </span>
                {/* <h5 className="text-lg font-medium mb-1">{slide.title}</h5>
                <p className="text-sm">
                  {slide.author} on {slide.date}
                </p> */}
              </div>
            </div>
          ))}
        </div>
        
        {/* Pagination Dots */}
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-gray-800' : 'bg-gray-400'}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-sm space-y-6 shadow-md">
  <h4 className="font-semibold text-gray-900 text-sm uppercase tracking-wide">New Courses</h4>
  <div className="space-y-4">
    {[
      { 
        title: "AI And Machine Learning", 
        description: "Learn algorithms and applications of AI.",
        icon: logo1 // Replace with the correct path to the icon image
      },
      { 
        title: "Cybersecurity Engineering", 
        description: "Protect networks and data in the digital age.",
        icon: logo1
      },
      { 
        title: "Sustainable Energy Systems", 
        description: "Study renewable energy technologies.",
        icon: logo2
      },
      { 
        title: "Robotics And Automation", 
        description: "Design and program robots for real-world tasks.",
        icon: logo3
      }
    ].map((course, index) => (
      <div key={index} className="flex items-start space-x-4">
        <img 
          src={course.icon} 
          alt={`${course.title} Icon`} 
          className="w-10 h-10 object-contain"
        />
        <div>
          <h5 className="font-semibold text-gray-900">{course.title}</h5>
          <p className="text-sm text-gray-600">{course.description}</p>
        </div>
      </div>
    ))}
  </div>
</div>

    </div>
    </div>
  );
};

export default ProfileStoriesUI;
