import React from "react";
import { Link } from "react-router-dom";

const GlobalCitizens = () => {
  const scrollTotop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="relative overflow-auto pb-16"
      style={{
        backgroundImage: "url(/abtbg.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <div className="flex items-center justify-center h-full px-6 md:px-16 ">
        <div className="text-black max-w-lg text-center">
          {/* Mission Statement */}
          <h1 className="text-4xl md:text-5xl font-semibold mb-4 text-[#233871] font-poppins">
            {" "}
            {/* Poppins font for headings */}
            Our Mission
          </h1>
          <h2 className="text-3xl capitalize md:text-4xl font-bold mb-4 text-[#233871] font-poppins">
            {" "}
            {/* Poppins font for headings */}
            We are building the{" "}
            <span className="text-[#f6c16f]">global citizens</span> <br /> of
            tomorrow
          </h2>

          {/* Flags */}
          <div className="flex space-x-4 mt-4 justify-center">
            {" "}
            {/* Added justify-center for center alignment */}
            <img
              src="https://assets.leverageedu.com/about-us/UK.png"
              alt="UK Flag"
              className="w-12 h-auto md:w-16"
            />
            <img
              src="https://assets.leverageedu.com/about-us/Canada.png"
              alt="Canada Flag"
              className="w-12 h-auto md:w-16"
            />
            <img
              src="https://assets.leverageedu.com/about-us/US.png"
              alt="US Flag"
              className="w-12 h-auto md:w-16"
            />
            <img
              src="https://assets.leverageedu.com/about-us/Australia.png"
              alt="Australia Flag"
              className="w-12 h-auto md:w-16"
            />
          </div>
          <p className="mt-2 text-gray-500">and more</p>

          {/* Button */}
          {/* <Link
            onClick={scrollTotop}
            to="/otp"
            className="hover:text-blue-700 font-medium flex pr-[1rem] text-[15px] items-center justify-center pb-0 text-grey-900"
          >
            <button className="mt-6 px-6 py-3 bg-[#233871] text-white font-semibold rounded-full hover:bg-[#2338718a] transition duration-300">
              Join Us
            </button>
          </Link> */}
          <div className="flex cursor-pointer items-center justify-center">
            <Link
              to="/otp"
              class="group relative z-0 bg-[#233871] bg rounded-full w-[50%]  hover:bg-gradient-to-r hover:from-[#2338718a] hover:to-[#4e7cfb] hover:ext-black transition-all flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-6 py-3 text-white transform-gpu duration-300 ease-in-out active:translate-y-[1px] mt-[1.6rem]"
            >
              Join Us
              <span class="group-hover:rotate-45 ml-4 ">↗</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalCitizens;
