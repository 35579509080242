import React from "react";
import l1 from "../Images/Destination/12979 1.png";
import Engineerings from "../components/EngineeringComponenet.jsx/Engineerings";
import EngineeringEducation from "../components/EngineeringComponenet.jsx/EngineeringEducation";
import EngineeringStory from "../components/EngineeringComponenet.jsx/EngineeringStory";
import EngineeringCors from "../components/EngineeringComponenet.jsx/EngineeringCors";
import EngineeringImage from "../components/EngineeringComponenet.jsx/EngineeringImage";
import Pg from "../components/EngineeringComponenet.jsx/Pg";

const Engineering = () => {
  return (
    <div className="pt-32">
      <Engineerings />
      <EngineeringEducation />
      <div className="pb-10">
        <div className="space-y-4 mb-4 text-center">
          <h1 className="text-4xl font-bold text-navy-900">
            What we offer
            {/* <span className="text-orange-400 block">
              Engineering Education?
            </span> */}
          </h1>
          <p className="text-gray-600 text-lg">
            Unleash Your Potential with a Future-Ready Engineering Program
            Designed for Success.
          </p>
        </div>
        <Pg />
      </div>
      <EngineeringStory />
      <EngineeringImage />
      <EngineeringCors />
      {/* <Engineerings /> */}
      {/* <EngineeringEducation /> */}
      {/* <EngineeringStory  />
     <EngineeringCors/> */}
    </div>
  );
};

export default Engineering;
