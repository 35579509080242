// import React from 'react';
// import Img from '../../Images/FeaturedImage.jpg'; // Replace with the correct path for the image

// const FeaturedSection = () => {
//   return (
//     <section
//       className="relative h-96 w-5/6	 bg-cover bg-center "
//       style={{ backgroundImage: `url(${Img})` }}
//     >
//       <div className="absolute inset-0 bg-black opacity-40 container mx-auto"></div>
//       <div className="relative z-10 text-center text-white flex items-center justify-center h-full">
//         <div>
//           <span className="block text-lg px-2 py-1 rounded-lg mb-4 inline-block" style={{borderColor:"rgba(0, 0, 0, 0.5)"}}>Travel</span>
//           <h2 className="text-4xl font-bold" style={{fontFamily:"Europa-Bold"}}>Richird Norton photorealistic rendering as real photos</h2>
//           <div className="font-normal" style={{fontFamily:"EuropaNuova-Bold"}}>
//           <p className="mt-4 text-lg">
//             Progressively incentivize cooperative systems through technically sound functionalities.
//             <p>
//             The credibly productive seamless data.
//             </p>
//           </p>
//           </div>
//           <button className="mt-6 px-6 py-3 bg-white rounded-lg shadow-lg text-lg" style={{color:"#7A65E1",fontFamily:"EuropaNuova-Bold",fontWeight:"400",borderRadius:"2px"}}>
//             Start planning your trip
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default FeaturedSection;

import React from "react";
import Img from "../../Images/FeaturedImage.jpg"; // Replace with the correct path for the image

const FeaturedSection = () => {
  return (
    <section
      className="py-16 relative h-96 bg-cover bg-center"
      style={{
        backgroundImage: `url(${Img})`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-40 container mx-auto"></div>
      <div className="relative z-10 text-center text-white flex items-center justify-center h-full">
        <div>
          <span
            className="block text-lg px-2 py-1 rounded-lg mb-4 "
            style={{ borderColor: "rgba(0, 0, 0, 0.5)" }}
          >
            Travel
          </span>
          <h2 className="text-4xl font-bold max-sm:text-lg">
            Richird Norton photorealistic rendering as real photos
          </h2>
          <div className="font-normal">
            <p className="mt-4 text-lg max-sm:text-md">
              Progressively incentivize cooperative systems through technically
              sound functionalities.
              <p>The credibly productive seamless data.</p>
            </p>
          </div>
          <button
            className="mt-6 px-6 py-3 bg-white rounded-lg shadow-lg text-lg max-sm:text-base max-sm:px-3 max-sm:py-2"
            style={{
              color: "#7A65E1",

              fontWeight: "400",
              borderRadius: "2px",
            }}
          >
            Start planning your trip
          </button>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;
