import React from "react";
import sidebarImage2 from "../../Images/topDes2.jpg";
import sidebarImage3 from "../../Images/topDes3.jpg";

const SidebarPosts = () => {
  const sidebarPosts = [
    {
      title:
        "Tick one more destination off of your bucket list with one of our most popular vacations in 2022",
      date: "31 March 2021",
      image: sidebarImage3,
    },
    {
      title:
        "Tick one more destination off of your bucket list with one of our most popular vacations in 2022",
      date: "31 March 2021",
      image: sidebarImage2,
    },
    {
      title:
        "Tick one more destination off of your bucket list with one of our most popular vacations in 2022",
      date: "31 March 2021",
      image: sidebarImage3,
    },
  ];

  return (
    <div className="w-full lg:w-2/5 bg-white border  rounded-lg p-4">
      <h3
        className="text-lg font-bold mb-3"
        style={{ fontFamily: "Europa-Bold" }}
      >
        Manga reads
      </h3>
      <div className="space-y-4">
        {sidebarPosts.map((post, index) => (
          <div
            key={index}
            className="flex space-x-4 items-start max-sm:flex-col "
          >
            <img
              src={post.image}
              alt={post.title}
              className=" w-64 h-28 object-cover rounded-lg shadow-md max-sm:w-full"
            />
            <div>
              <h4 className="text-md font-bold max-sm:mt-4 ">{post.title}</h4>
              <p className="text-xs text-gray-500 font-normal mt-6">
                {post.date}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarPosts;
