import React from "react";
import TopDesImg1 from "../../Images/topDes1.jpg";
import TopDesImg2 from "../../Images/topDes2.jpg";
import TopDesImg3 from "../../Images/topDes3.jpg";

const destinations = [
  { name: "Dominican Republic", image: TopDesImg1 },
  { name: "Maecenas Tincidunt", image: TopDesImg2 },
  { name: "Dominican Republic", image: TopDesImg3 },
  { name: "Dominican Republic", image: TopDesImg1 },
  { name: "Dominican Republic", image: TopDesImg2 },
];

const TopDestinations = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-2xl font-bold ">Top Destinations</h2>
        <p className="text-md font-bold text-black mb-12">
          Tick one more destination off your bucket list with one of our most
          popular vacations in 2022.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {destinations.map((destination, index) => (
            <div
              key={index}
              className="relative h-48 bg-cover bg-center rounded-lg shadow-md"
              style={{ backgroundImage: `url(${destination.image})` }}
            >
              <div className="absolute inset-0 bg-black opacity-30"></div>
              <div className="absolute inset-0 flex items-center justify-center text-white text-xl font-bold">
                {destination.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopDestinations;
